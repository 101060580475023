var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',{staticClass:"fit column",attrs:{"padding":""}},[(_vm.isLoading)?_c('d-loader'):_vm._e(),_c('div',{staticClass:"full-width"},[_vm._t("default",null,null,{
        loading: _vm.isLoading,
        find: _vm.find,
        selectedData: _vm.selectedData,
        removeHandler: _vm.removeHandler,
        data: _vm.data,
        setLoading: _vm.setLoading,
        filtersEntity: _vm.filtersEntity,
      })],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }